import $ from 'jquery';
import { gsap, TimelineMax, Power2, Power4, CSSRulePlugin, ScrollTrigger } from 'gsap/all';
require("../__vendor/SplitText.min.js");

gsap.registerPlugin(CSSRulePlugin, ScrollTrigger); 

const loader = () => {
    console.log("LOADER");

    let loaderTl = gsap.timeline({
        paused: true
    });

    // loaderTl.staggerTo(".shape", 1, {
    //     // scale: 1,
    //     // ease: Power4.ease
    // }, 0.1);

    loaderTl.set(".content", {
        className: "content active"
    }) .set(".under-construction", {
        className: "under-construction shape-active"
    }, 0.5).to(".text", 0.4, {
        autoAlpha: 1,
        ease: Power4.ease
    }, 1).set(".c-1", {
        className: "c c-1 active"
    }, 1.175).set(".c-2", {
        className: "c c-2 active"
    }, 1.175).set(".c-3", {
        className: "c c-3 active"
    }, 1.175).set(".c-4", {
        className: "c c-4 active"
    }, 1.175).set(".c-5", {
        className: "c c-5 active"
    }, 1.175).to(".line-1", 0.6, {
        scaleY: 1,
        ease: Power4.ease
    }, 1.6).to(".line-2", 0.6, {
        scaleX: 1,
        ease: Power4.ease
    }, 1.6).to(".line-3", 0.6, {
        scaleY: 1,
        ease: Power4.ease
    }, 1.6).to(".line-4", 0.6, {
        scaleX: 1,
        ease: Power4.ease
    }, 1.6).set(".content-header", {
        className: "content-header content-header--show"
    }, 1.6).set(".content-center--left", {
        className: "content-center--left content-center--left-active"
    }, 1.6).to(".content-top img", 1, {
        autoAlpha: 1,
        ease: Power4.ease
    }, 1.5).to(".content-header a", 0.65, {
        autoAlpha: 1,
        scale: 1,
        ease: Power4.ease
    }, 1.75).to("#web", 1, {
        autoAlpha: 1,
        ease: Power4.ease
    }, 1.8).to("#circle", 0.65, {
        autoAlpha: 1,
        ease: Power4.ease
    }, 2.15).staggerTo(".content-center--left p", 2, {
        autoAlpha: 1,
        ease: Power4.ease
    }, 0.15, 2.25).to("#eye", 1, {
        autoAlpha: 1,
        ease: Power4.ease
    }, 2.2).staggerTo(".content-center--right p, .content-center--right a", 1, {
        autoAlpha: 1,
        ease: Power4.ease
    }, 0.1, 2.3).to("#satellite", 1, {
        autoAlpha: 1,
        ease: Power4.ease
    }, 2.15);

    setTimeout(() => {

    loaderTl.play();
    }, 500);
};

export default loader;