import "@babel/polyfill";
import $ from 'jquery';
import loader from './__modules/loader';

document.addEventListener('DOMContentLoaded', () => {
	loader();
});

window.addEventListener('load', () => {
});

window.addEventListener('resize', () => {
});

window.addEventListener('scroll', () => {
});